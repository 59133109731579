.contact-form-wrap{

  .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 0px var(--main-color-one);
  }

  .ant-select-selector:focus {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-open {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-open:hover {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 0px var(--main-color-one);
  }

  .ant-select:hover {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 0px var(--main-color-one);
  }

  .ant-select-focused {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-single:hover {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 0px var(--main-color-one);
  }

  .ant-select-show-arrow:hover {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 0px var(--main-color-one);
  }

  .ant-select-show-search:hover {
    border: 0.1px solid var(--main-color-one);
    box-shadow: 0 0 0 0px var(--main-color-one);
  }

  .ant-picker-focused {
    border-color: var(--main-color-one) !important;
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-picker-range .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: var(--main-color-one);
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }

  .ant-picker-range:hover {
    border-color: var(--main-color-one) !important;
    box-shadow: 0 0 0 0px var(--main-color-one);
  }

  //.ant-select:not(.ant-select-customize-input){
  //  border: 1px solid var(--main-color-one);
  //  box-shadow: 0 0 0 1px var(--main-color-one);
  //}
}
