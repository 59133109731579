.filter {
  .select {
    margin-top: 0 !important;
  }

  .widget-sidebar-search .widget-sidebar-item-wrap {
    margin-bottom: 10px;
  }

  .widget-sidebar-search {
    float: unset;
  }

  .myCat {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.5;
    color: var(--heading-color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .catHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .catName {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 2.5;
      color: var(--heading-color);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }

    .arrowIcon {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
    }
  }

  .highlight {
    color: #000 !important;
    font-weight: bolder !important;
  }

  .highlight-main {
    color: var(--main-color-one) !important;
    font-weight: bold !important;
  }

  .subCat {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: var(--paragraph-color);
    margin-bottom: 15px;
    margin-left: 10px;
    cursor: pointer;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid transparent !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-selector:focus {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-open {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-open:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-focused {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-single:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-show-arrow:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-select-show-search:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-picker-focused {
    border-color: var(--main-color-one) !important;
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .ant-picker-range .ant-picker-active-bar {
    bottom: -1px;
    height: 2px;
    margin-left: 11px;
    background: var(--main-color-one);
    opacity: 0;
    transition: all 0.3s ease-out;
    pointer-events: none;
  }

  .ant-picker-range:hover {
    border-color: var(--main-color-one) !important;
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .anticon {
    color: var(--component-color) !important;
  }
}

.noData {
  display: none !important;
}

.date-picker-flat{
  .form-control:disabled, .form-control[readonly] {
    background-color: white;
    opacity: 1;
    height: 48px;
  }
}
