
.login-Alert {
  display: flex;
  justify-content: center;
  margin-top: 23px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: var(--heading-color);
  line-height: 21px;
}
.req-star {
color: red;
}
.login {
  .form {
    background: #FFFFFF;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .loginBtn {
    padding: 0 20px;
    height: 40px;
    line-height: 0;
  }

  .forgotPW {
    margin-top: 23px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 48px;
    cursor: pointer;
    color: var(--main-color-one);
  }

  .dont {
    display: flex;
    justify-content: center;
    margin-top: 23px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    color: var(--heading-color);
    line-height: 21px;
  }

  .register {
    cursor: pointer;
    color: var(--main-color-one);
  }

  .page-title {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .justify-content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .page-title {
      margin-top: 30px;
      margin-bottom: 15px;
      font-size: 25px;
    }
  }
}

.register {
  .ant-select {
    margin-top: 0px !important;
  }

  .form {
    background: #FFFFFF;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .choose2 {
    color: var(--main-color-one);
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    margin: 0 20px;
    padding: 5px 15px;
    cursor: pointer;
    border: none;
    filter: none;
  }

  .companyImage {
    width: 100px;
    height: 100px;
    border: 1px solid #CFD3DE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
  }

  .companyCoverImage {
    width: 300px;
    height: 100px;
    border: 1px solid #CFD3DE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    object-fit: contain;
  }

  .add {
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    color: var(--main-color-one);
    cursor: pointer;
  }

  .remove {
    text-align: right;
    font-weight: 500;
    font-size: 14px;
    color: var(--main-color-two);
    cursor: pointer;
  }

  .register {
    cursor: pointer;
    color: var(--main-color-one);
  }

  .page-title {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .justify-content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page-subTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 25px;
  }

  .regTile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .regImg {
    width: 200px;
    margin-top: 35px;
  }

  .regTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    margin: 15px;
    text-transform: uppercase;
    color: var(--heading-color);
  }

  .regSubTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    color: var(--heading-color);
    line-height: 21px;
  }

  .regSubTitle p {
    font-size: 20px;
    color: var(--main-color-one);
  }

  .loginBtn {
    padding: 0 60px;
    height: 40px;
    line-height: 0;
    margin-bottom: 45px;
  }

  .lineSeparator {
    width: 1px;
    height: 100%;
    background: var(--main-color-one);
    opacity: 0.3;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
  }

  .or {
    background: white;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--main-color-one);
    border: 1px solid white;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    text-align: center;
    align-items: center;
    box-shadow: 0px 4px 22px rgba(0, 100, 227, 0.3);
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }

  .dont {
    display: flex;
    justify-content: center;
    margin-top: 23px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    color: var(--heading-color);
    line-height: 21px;
  }

  .contact-form-wrap {
    box-shadow: none;
    padding: 15px;
  }

  .file-wrapper {
    background: #fff;
    border: 1px solid #CFD3DE;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    display: flex;
    align-items: center;
  }

  .choose {
    background: #FFFFFF;
    border: 1px solid var(--main-color-one);
    color: var(--main-color-one);
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0 20px;
    padding: 5px 20px;
    filter: drop-shadow(2px 4px 15px rgba(0, 124, 214, 0.2));
    cursor: pointer;
  }

  .view-file {
    padding: 0;
    color: var(--main-color-one);
    margin: 0;
    cursor: pointer;
  }

  .remove-file {
    padding: 0;
    color: var(--main-color-one);
    margin: 0;
    cursor: pointer;
  }

  .package {
    .packageHolder {
      cursor: pointer;
      background: white;
      box-shadow: 0 5px 22px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .recommended {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      color: var(--main-color-one);
      text-align: center;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 25px;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      padding-top: 40px;
      padding-bottom: 0;
      margin-bottom: 5px;
      z-index: 1;
      position: absolute;
    }

    .priceTag {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: var(--heading-color);
      display: flex;
      justify-content: center;
    }

    .price {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 48px;
      color: var(--heading-color);
      margin: 0;
    }

    .userTag {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: var(--paragraph-color);
    }

    .checkedImg {
      position: absolute;
      right: 4px;
      top: 5px;
      width: 13px;
    }

    .cardBg {
      width: 100%;
      height: 120px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .packageHolder:hover {
    box-shadow: 2px 4px 15px rgba(0, 124, 214, 0.2);
  }

  @media only screen and (max-width: 991px) {
    .page-title {
      margin-top: 30px;
      margin-bottom: 15px;
      font-size: 25px;
    }
    .page-subTitle {
      font-size: 18px;
    }
    .regImg {
      width: 150px;
    }
    .regTitle {
      font-size: 25px;
    }
    .loginBtn {
      margin-bottom: 40px;
    }
    .lineSeparator {
      width: 100%;
      height: 1px;
      top: 0;
      bottom: 0;
      opacity: 0.2;
    }
    .modal-dialog {
      margin: auto;
      margin-top: 5rem;
      max-width: 85%;
    }
    .package {
      .priceTag {
        font-size: 12px;
      }

      .price {
        font-size: 25px;
      }
    }
  }
}

.forgot {
  .modal-dialog {
    max-width: 500px !important;
  }

  .sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: var(--heading-color);
  }

  .steps {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: var(--paragraph-color);
    margin: 0;
  }

  .btn {
    width: 100%;
  }

  .optgroup {
    width: 50px !important;
    height: 50px !important;
  }
}

.payment {
  .paymentHolder {
    cursor: pointer;
    background: white;
    box-shadow: 0 5px 22px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    //align-items: center;
    display: flex;
    flex-direction: column;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 0;
      color: var(--heading-color);
    }

    .inputTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      margin-bottom: 0;
      margin-top: 1rem;
      color: var(--heading-color);
    }

    .inputTitle span {
      color: red;
    }

    .checkoutDescription {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      margin-top: 1rem;
      color: var(--heading-color);
    }

    .validity {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-top: .5rem;
      color: var(--heading-color);
    }

    .subHolder {
      border: 1px solid #CFD3DE;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .subName {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }

    .subPrice {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      //line-height: 27px;
      text-align: center;
    }

    .subValidity {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }

    .btn {
      height: 48px !important;
      line-height: 48px;
      padding: 0;
      width: 100%;
      margin: 0;
    }

    .paymentDetailsHolder {
      display: flex;
      justify-content: space-between;
    }

    .paymentItem {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: var(--heading-color);
    }

    .paymentItem2 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: var(--paragraph-color);
    }
  }
}

.secondary {

  .wrapper {
    background-color: #f3f3f3;
    width: 100%;
    border: 1px solid #D4D4D4;
    margin-top: 10px;
  }

  .wrapper2 {
    background-color: #f3f3f3;
    width: 100%;
    border: 1px solid #D4D4D4;
    margin-bottom: 15px;
  }

  .input {
    width: 100%;
    height: 45px;
    padding: 0 1rem;
    border: none;
    -webkit-appearance: none;
  }

  .input:focus {
    border-color: cornflowerblue;
    outline: none;
  }

  .input.has-error {
    border-color: tomato;
  }

  .error-label {
    margin: 0;
    font-size: 90%;
    color: tomato;
    margin-left: 10px;
  }

  .tag-item {
    background-color: #d4d5d6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0.5rem;
  }

  .tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    margin-top: 0;
    justify-content: center;
  }

}

@media only screen and (max-width: 991px) {
  .register {
    .companyCoverImage {
      width: 200px;
      height: 100px;
      border: 1px solid #CFD3DE;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 5px;
      object-fit: contain;
    }
  }
}

.lbl-register {
  color: var(--main-color-one);
  font-weight: bold;
}
