.view-supplier {

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 27px;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .sub-title {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 2;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .headline {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 2.5;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .headline .c1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    color: #21AA68;
  }

  .headline .c2 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 2.5;
    color: var(--paragraph-color);
  }

  .about{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.7;
    color: var(--paragraph-color);
  }

  .headline .c3 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 2.5;
    color: var(--main-color-one);
  }

  .lineHeight {
    line-height: 1.7 !important;
  }

  .review {
    margin-top: 1rem;
  }

  .searchBtn{
    height: 48px !important;
  }

  .profileDetailsHolder {
    background: #fff;
    border: 1px solid #CFD3DE;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 15px;


    .companyDetails {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 20px 0 10px 0;

      .logo {
        width: 100px;
        height: 100px;
        border: 1px solid #CFD3DE;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 5px;
      }

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--heading-color);
        margin: 10px 0;
      }
    }

    .fa {
      width: 11px;
      color: var(--main-color-one)
    }

    .contactDetails {
      color: var(--paragraph-color);
      margin-left: 23px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 0;
      word-wrap: break-word;
    }
  }


  .anticon{
    color: unset !important;
  }

  .ratingValue{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #191B1C;
  }

  .ratingCount{
    color: var(--main-color-one);
  }

  .property-filter-menu button:hover, .property-filter-menu button:focus, .property-filter-menu button.active {
    background: unset;
    color: var(--main-color-one);
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: 1px solid;
    border-bottom: 0;
  }

  .property-filter-menu button {
    background-color: #fff;
    border-bottom: 1px solid;
    color: var(--paragraph-color);
    border-color: #CFD3DE;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background: white !important;
  }

  .actionBtn{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    margin: 1rem 10px 0px 0px;
    color: var(--main-color-one);
  }

  .review{
    .ratingValue{
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 1;
      color: var(--paragraph-color);
    }
    .ant-rate{
      font-size: 25px !important;
      line-height: 1 !important;
    }

    .rateItem{
      .ratingImage{
        border: 1px solid #CFD3DE;
        box-sizing: border-box;
        border-radius: 45px;
        width: 50px;
        height: 50px;
      }
      .ratingName{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        color: var(--heading-color);
      }
      .companyName{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        color: var(--paragraph-color);
      }

      .ant-rate{
        font-size: 15px !important;
        line-height: 1 !important;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .ref {
      font-size: 20px;
    }
    .title {
      font-size: 15px;
    }
    .ant-select {
      margin-top: 0 !important;
    }

    .review{
      .ratingValue{
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 1;
        color: var(--paragraph-color);
      }
      .ant-rate{
        font-size: 22px !important;
        line-height: 1 !important;
      }
    }
  }

  @media only screen and (max-width: 767px){
    .searchBtn{
      margin-top: 15px;
    }

    .ant-select {
      margin-top: 15px !important;
    }
  }
}

.rateModal{
  .modal-title{
    margin: 0;
  }
  .anticon{
    color: unset !important;
  }
  .ant-rate{
    font-size: 35px !important;
    line-height: 1 !important;
  }
}
