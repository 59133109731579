.navBar {
  .oauthHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .oauthHolder-mobile {
    display: none;
  }

  .or {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: var(--heading-color);
    margin: 0;
    padding: 0 5px;
  }

  .login {
    padding: 0;
    color: var(--main-color-one);
  }

  @media only screen and (max-width: 991px) {
    .oauthHolder {
      display: none;
    }
    .oauthHolder-mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

}

.cursor-handle {
  cursor:  pointer !important;
}

.popularSupplier {
  .supplierHolder {
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-family: Poppins;

      a {
        color: var(--heading-color) !important;
      }
    }

    .company {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      a {
        color: var(--heading-color) !important;
      }

      .fa {
        color: var(--main-color-one);
      }
    }

    .category {
      font-style: normal;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 18px;
      color: var(--main-color-one);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .category > a {
      color: var(--main-color-one) !important;
    }

    .info-list {
      padding: 0;
      margin: 0;
      font-size: 14px;
      border-top: 0;

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

      }
    }

    .product {
      width: 100%;
      height: 150px;
    }

    .objectFit {
      object-fit: cover;
    }

    .contact-list .readeal-top {
      width: 100%;

      .btn-yellow {
        width: 100%;
      }
    }

    .c3 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      color: var(--main-color-one);
    }

    .anticon {
      color: unset !important;
    }
  }

  .partnerHolder {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;

    .cat {
      height: 50px;
      width: fit-content;
      margin-top: 26px;
    }

    .details {
      background: #FFFFFF;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: var(--heading-color) !important;

      a {
        color: var(--heading-color) !important;
      }
    }

    .sub-title {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: var(--paragraph-color) !important;
    }
  }

  .catHolder {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    //background: #FFFFFF;
    //border: 1px solid #CFD3DE;
    box-sizing: border-box;
    width: 100%;
    height: 205px;
    //border-radius: 10px;

    .category-bg-image {
      position: absolute;
      z-index: 1;
    }

    .custom-category-card {
      background-color: transparent !important;
      z-index: 9999 !important;
    }

    .custom-category-card-wrap {
      background-color: transparent !important;
      z-index: 9999 !important;
      padding: 0 20px 0 20px !important;
    }

    .custom-category-title {
      color: #fff !important;
    }

    .custom-category-title-wrap {
      color: #fff !important;
      //padding: 0 20px 0 20px !important;
      width: 140px;
    }

    .custom-category-sub-title {
      color: #fff !important;
      font-size: 12px;
    }

    .cat {
      height: 50px;
      width: auto;
      margin-top: 26px;
    }

    .details {
      background: #FFFFFF;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: var(--heading-color) !important;

      a {
        color: var(--heading-color) !important;
      }
    }

    .sub-title {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: var(--paragraph-color) !important;
    }
  }

  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 1.5;
    color: var(--heading-color);
    margin-bottom: 0 !important;
    //width: 85%;
  }

  .requestHolder {
    cursor: pointer;
    background: #FFFFFF !important;
    border: 1px solid var(--main-color-one);
    box-sizing: border-box;
    border-radius: 10px;

    li {
      list-style: none;
    }

    .details {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      overflow: hidden;
      line-height: 26px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      //margin-bottom: 15px !important;

      a {
        color: var(--heading-color) !important;
      }
    }

    .info-list {
      padding: 10px 0 0 0;
      margin: 0;
      font-size: 14px;
      border-top: 0;
    }

    .product {
      width: 100%;
      height: 150px
    }

    .contact-list .readeal-top {
      width: 100%;

      .btn-yellow {
        width: 100%;
      }
    }

    .date {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 2;
      color: var(--heading-color);

      b {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
      }
    }

    .category {
      font-style: normal;
      font-weight: bold;
      //margin-top: 25px;
      font-size: 12px;
      line-height: 18px;
      color: var(--main-color-one);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    @media only screen and (max-width: 767px) {
      .title {
        margin-top: 0px !important;
      }
    }

    .landing-latest-request-footer {
      //background-color: #00b86c;
      height: 50px;
      text-align: center;
      padding: 13px 0;
      color: #fff;
      font-weight: bold;
    }

    .remaining {
      position: absolute;
      right: 9px;
      bottom: 15px;
      padding: 5px 20px;
      background: var(--main-color-three);
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .myProduct {
    .thumb img {
      max-height: 220px;
    }

    .single-feature.style-two .details {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 60% !important;
      flex: 0 0 60%;
    }

    .title {
      line-height: 1.5;
    }

    .c3 {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 1.5;
      color: var(--main-color-one);
    }

    .description {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 1.5;
      -webkit-line-clamp: 2 !important;
      color: var(--paragraph-color);
    }

    .c2 {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 1.7;
      color: var(--paragraph-color);
    }

    .actionHolder {
      position: absolute;
      right: 15px;
      bottom: 16px;
      top: unset;
      display: flex;
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 575px) {
    .supplierHolder {
      margin: 0 15px 15px 15px;
    }
    .single-feature.style-two .thumb {
      min-height: 200px !important;
    }
    .single-feature.style-two .details {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100% !important;
      flex: 0 0 100% !important;
    }
  }
  @media only screen and (max-width: 400px) {
    .details {
      padding-bottom: 55px !important;
    }
    .remaining {
      right: unset !important;
      bottom: 15px !important;
    }
  }

  .categories {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    color: var(--heading-color);
    margin-bottom: 0;
    max-height: 42px;
    overflow: hidden;

    p {
      margin-bottom: 0;
      line-height: 1.5 !important;
    }
  }

  .supplierDetail {
    min-height: 183px;
  }
}

.testimonials {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: -185px;
  padding-top: 50px !important;

  .title {
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 1;
    text-align: center;
    color: #000;
    margin-top: 10px;
  }

  .comment {
    text-align: left;
    color: #000;
    z-index: 600;
    padding: 0 0 20px 0;
    margin: 0;
    font-size: 15px;
    //font-style: italic;
    line-height: 1.4 !important;
    font-family: Helvetica Neue, Helvetica, Arial;
    margin-bottom: 0 !important;
    text-align: center;
  }

  .comment:first-child:before {
    content: "“";
    color: rgba(255, 255, 225, 0.44);
    font-size: 7.5em;
    font-weight: 700;
    opacity: 1;
    position: absolute;
    top: -20px;
    left: 13px;
    text-shadow: none;
    z-index: -10;
  }

  .author {
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: var(--main-color-one);
    margin-bottom: 0 !important;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
  }

  .designation {
    color: #000;
    margin-bottom: 0 !important;
    text-align: center;
    font-weight: bold;
  }

  .txt-sub-title {
    color: var(--main-color-one);
    font-weight: bold;
    letter-spacing: 4px;
    display: block;
  }
}

.pricing {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: white;
  }
}

.package {
  .packageHolder {
    cursor: pointer;
    background: white;
    box-shadow: 0 5px 22px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .recommended {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: var(--main-color-one);
    text-align: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 25px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 5px;
    z-index: 1;
    position: absolute;
  }

  .priceTag {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: var(--heading-color);
    display: flex;
    justify-content: center;
  }

  .price {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: var(--heading-color);
    margin: 0;
  }

  .userTag {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: var(--paragraph-color);
    cursor: pointer;
  }

  .checkedImg {
    position: absolute;
    right: 4px;
    top: 5px;
    width: 13px;
  }

  .cardBg {
    width: 100%;
    height: 120px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.change-package {
  .packageHolder {
    cursor: pointer;
    background: white;
    box-shadow: 0 5px 22px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .recommended {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: var(--main-color-one);
    text-align: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 25px;
  }

  .change-package-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 5px;
    z-index: 1;
    position: absolute;
  }

  .priceTag {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: var(--heading-color);
    display: flex;
    justify-content: center;
  }

  .price {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: var(--heading-color);
    margin: 0;
  }

  .userTag {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: var(--paragraph-color);
    cursor: pointer;
  }

  .checkedImg {
    position: absolute;
    right: 4px;
    top: 5px;
    width: 13px;
  }

  .cardBg {
    width: 100%;
    height: 120px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}


.productViewModal {
  .modal-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 2;
    text-align: left;
    color: var(--heading-color);
    font-family: var(--body-font) !important;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .productImage {
    width: 100%;
    height: auto;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 1;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .sub-title {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 1.7;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .headline {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 2;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .headline .c1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: var(--main-color-one);
  }

  .headline .c3 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 1;
    color: var(--main-color-one);
  }

  .c2 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.7;
    color: var(--paragraph-color);
  }

  .productAttribute {
    display: flex;
    flex-direction: row;
  }

  .myProduct {
    .choose {
      width: fit-content;
    }

    .ant-select {
      margin-top: 0 !important;
    }

    .remove {
      text-align: right;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 1;
      cursor: pointer;
      color: var(--main-color-two);
    }

    .add {
      text-align: right;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;
      cursor: pointer;
      color: var(--main-color-one);
    }
  }
}

.error {
  width: 100%;
  position: absolute;
  top: 70px;
  z-index: 1000000;
  //background: white;
  padding: 12px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  //height: 50px;
  align-items: center;
  align-content: center;

  .error-inner {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    //height: 50px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.2));
  }

  .text {
    font-style: normal;
    font-size: 15px;
    text-align: center;
    color: #EA4343;
    letter-spacing: 1px;
  }

  .btn-upgrade {
    background-color: #fff;
    border: 1px solid #EA4343;
    border-radius: 4px;
    height: 27px;
    color: #EA4343;
    margin-left: 50px;
  }

  .btn-upgrade:hover {
    background-color: #EA4343;
    color: #fff;
    cursor: pointer;
  }

  .img-warning {
    margin-left: 5px;
    margin-right: 20px;
  }

  .btn-close:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  @media only screen and (max-width: 575px) {
    .error-inner {
      display: flex;
      flex-direction: column;
    }

    .img-warning {
      margin-bottom: 10px;
      margin-right: 0px;
    }

    .text {
      margin-bottom: 10px;
    }

    .btn-upgrade {
      margin-left: 0px;
    }

    .btn-close {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }

}

.error2 {
  width: 100%;
  position: absolute;
  top: 130px;
  z-index: 1000000;
  //background: white;
  padding: 12px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  //height: 50px;
  align-items: center;
  align-content: center;

  .error-inner {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    //height: 50px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.2));
  }

  .text {
    font-style: normal;
    font-size: 15px;
    text-align: center;
    color: #EA4343;
    letter-spacing: 1px;
  }

  .btn-upgrade {
    background-color: #fff;
    border: 1px solid #EA4343;
    border-radius: 4px;
    height: 27px;
    color: #EA4343;
    margin-left: 50px;
  }

  .btn-upgrade:hover {
    background-color: #EA4343;
    color: #fff;
    cursor: pointer;
  }

  .img-warning {
    margin-left: 5px;
    margin-right: 20px;
  }

  .btn-close:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  @media only screen and (max-width: 575px) {
    .error-inner {
      display: flex;
      flex-direction: column;
    }

    .img-warning {
      margin-bottom: 10px;
      margin-right: 0px;
    }

    .text {
      margin-bottom: 10px;
    }

    .btn-upgrade {
      margin-left: 0px;
    }

    .btn-close {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }

}

@media only screen and (max-width: 1600px) {
  .testimonials {
    margin-top: -175px;
  }
}

@media only screen and (max-width: 991px) {
  .package {
    .priceTag {
      font-size: 12px;
    }

    .price {
      font-size: 25px;
    }
  }
  .testimonials {
    margin-top: -165px;

    .title {
      font-size: 24px;
    }
  }
  .error {
    display: block !important;
  }
}

@media only screen and (max-width: 800px) {
  .testimonials {
    margin-top: -165px;
  }
}

@media only screen and (max-width: 320px) {
  .testimonials {
    margin-top: -185px;
  }
}

.category-area {
  background-color: #ededed;
}

.txt-sub-title {
  color: var(--main-color-one);
  font-weight: bold;
  letter-spacing: 4px;
  display: block;
}

.searchTitle-new {
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
}

.catHolder > i {
  font-size: 10px !important;
  color: var(--main-color-one);
}

.catName {
  color: var(--main-color-one) !important;;
}

.property-filter-menu button {
  background-color: #fff;
  color: #000;
}

.property-filter-menu button:hover {
  background-color: #fff;
  color: var(--main-color-one);
  text-decoration: underline;
}

.property-filter-menu button.active {
  background-color: #fff;
  color: var(--main-color-one);
  text-decoration: underline;
  font-weight: bold;
}

.tender-card {
  .remaining-time-card {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    width: 200px;
    margin-right: -20px;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
  }

  .remaining-time-card-text {
    color: #fff;
    font-weight: bold;
  }

  .req-card-dates-pub {
    margin-top: 8px;
  }

  .req-card-dates-cls {
    margin-top: 8px;
  }

  .custom-card {
    padding: 10px 20px 0px 20px !important;
    width: 100%;
  }

  .title {
    margin-top: 5px;
  }
}

.rights-content-mobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .tender-card .remaining-time-card {
    height: 100%;
    padding: 10px 15px 10px 10px;
    width: 120%;
    margin-right: 0px;
    margin-left: -20px !important;
    clip-path: none;
    text-align: center;
    margin-bottom: 0px;
  }

  .tender-card .req-card-dates-pub {
    margin-top: 10px !important;
  }

  .tender-card .req-card-dates-cls {
    margin-bottom: 10px !important;
  }

  .single-feature.style-two .details {
    padding-bottom: 0 !important;
  }

  .details-wrap {
    margin-top: 35px;
  }

  //.title {
  //  margin-top: 35px !important;
  //}

  .rights-content-mobile {
    text-align: center;
    color: #fff;
    font-weight: bold;
    display: block;
  }

  .partner-title {
    text-align: center;
  }

  .custom-section {
    text-align: center;
  }
}

.custom-testimonials {
  .carousel-root > .carousel > .control-dots > .dot {
    background-color: var(--main-color-one) !important;
  }
}

.custom-section {
  margin-bottom: 10px !important;
}

//@media only screen and (min-width: 993px) and (max-width: 1199px) {
//  .slick-track > div {
//    margin-left: 10px !important;
//    margin-right: 10px !important;
//  }
//}

.video-modal {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.video-modal-close {
  height: 40px;
  width: 40px;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #fff;
  border-radius: 100%;
  border: 3px solid #fff;
}

.video-modal-close:hover {
  transform: scale(1.1);
}

.latest-tender-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 0px;
}

.Professisonal-area {
  .our-achievement {
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 1200px) {
    .our-achievement {
      padding-bottom: 20px !important;
    }
  }
}

.rld-single-input > div:hover {
  border: 0.2px solid var(--main-color-one) !important;
}

.ant-select-focused {
  border: 1px solid var(--main-color-one) !important;
  box-shadow: none !important;
}

.ant-select-focused > div {
  border: 0.2px solid var(--main-color-one) !important;
  box-shadow: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid transparent !important;
}
