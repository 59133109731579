.category-selection {
  .css-1pahdxg-control {
    border-color: var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .css-1pahdxg-control:hover {
    border-color: var(--main-color-one);
  }

  .ant-checkbox-input {
    background-color: var(--main-color-one) !important;
  }
}

.my-category {
  z-index: 99 !important;
}
