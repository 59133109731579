.requests {
  .searchTitle-new {
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
  }

  .searchTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: var(--heading-color);
  }

  .searchBtn {
    height: 48px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: white !important;
  }

  .titleHolder {
    display: flex;
    justify-content: space-between;
  }

  .advanceFilter {
    cursor: pointer;
    height: 20px;
    width: fit-content;
    color: red;
    font-size: 14px;
  }

  .inputTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    margin-bottom: .5rem;
    margin-top: 1rem;
    color: var(--heading-color);
  }

  .ant-picker {
    height: 48px !important;
    background-color: white !important;
  }

  .btnHolder {
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      width: fit-content;
      height: 45px !important;
      margin-left: 15px;
      padding: 0 40px;
    }
  }

  .add-new-request {
    position: absolute;
    right: 10px;
  }

  .single-feature.style-two .details .contact-list {
    flex-direction: column !important;
    display: flex !important;
    padding: 0 0 0 0;
  }

  .single-feature .details .author {
    color: var(--heading-color);
  }

  .single-feature .details .info-list {
    padding: 0;
  }

  .fa-trophy {
    color: var(--main-color-three);
  }

  .fa-star {
    color: #FFAC33;
  }

  .fa-ban {
    color: var(--main-color-two);
  }

  .remaining {
    position: absolute;
    right: 0px;
    bottom: 0;
    padding: 5px 20px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    border: 1px solid
  }

  .quoteStatus {
    padding: 5px 15px;
    border-radius: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    border: 1px solid
  }

  .fa-heart-o {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
  }

  .fa-heart {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    color: red;
  }

  .author span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--main-color-one);
  }

  .actionHolder {
    position: absolute;
    right: 10px;
    top: 5px;
    display: flex;
    flex-direction: row;
  }

  .actionHolder2 {
    position: absolute;
    right: 10px;
    bottom: 25px;
    display: flex;
    flex-direction: row;

  }

  .actionHolder3 {
    position: absolute;
    right: 10px;
    bottom: 0;
    display: flex;
    flex-direction: row;

    .btn {
      padding: 0 10px;
      height: 31px !important;
      line-height: 2 !important;
    }
  }

  .remaining2 {
    position: absolute;
    right: 10px;
    top: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .actionBtn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    margin: 1rem 10px 0px 0px;
    color: var(--main-color-one);
  }

  .actionBtn2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    margin: 0 10px 0px 0px;
    color: var(--main-color-one);
  }

  @media only screen and (max-width: 991px) {
    .searchTitle {
      font-size: 22px;
    }
    .titleHolder {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .advanceFilter {
      margin-bottom: 20px;
    }
    .ant-select {
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    .searchBtn {
      margin-top: 15px;
    }
  }
}

.suggest-list-content {
  font-size: 15px;
  cursor: pointer;
  padding-left: 2.5rem;
}

.custom-seeAllBtn {
  margin-left: 2.5rem;
  padding: 0 30px;
  border-radius: 4px;
  border: 0;
  transition: all 0.5s ease;
  font-weight: 600;
}

.view-request {
  .refHolder {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .fa-trophy {
    color: var(--main-color-one);
  }

  .fa-star {
    color: #FFAC33;
  }

  .fa-ban {
    color: var(--main-color-two);
  }

  .ref {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .fa-heart-o {
    font-size: 20px;
  }

  .fa-heart {
    font-size: 20px;
    color: red;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 27px;
    margin: 15px 0px 0px 0;
    color: var(--heading-color);
  }

  .sub-title {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 2;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .headline {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 2.5;
    margin-bottom: 0;
    color: var(--heading-color);
  }

  .headline .c1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    color: var(--main-color-one);
  }

  .headline .c2 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 2.5;
    color: var(--paragraph-color);
  }

  .headline .c3 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 2.5;
    color: var(--main-color-one);
  }

  .lineHeight {
    line-height: 1.7 !important;
  }

  .btn {
    //margin-top: 1rem;
    //width: 100%;
  }

  .profileDetailsHolder {
    background: #F6F7FB;
    border: 1px solid #CFD3DE;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 15px;

    .btn {
      width: 100%;
    }

    .companyDetails {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 20px 0 10px 0;

      .logo {
        width: 80px;
        height: 80px;
        border: 1px solid #CFD3DE;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 5px;
      }

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--heading-color);
        margin: 10px 0;
      }
    }

    .fa {
      width: 11px;
      color: var(--main-color-one)
    }

    .contactDetails {
      color: var(--paragraph-color);
      margin-left: 23px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .quotationHolder {

    .holder {
      border: 1px solid #CFD3DE;
      border-radius: 7px;
      padding: 15px 20px 10px 20px;
      background: #F6F6F6;
    }

    .detailsHolder {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 10px;
      color: var(--heading-color);
    }

    .view {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 10px;
      display: flex;
      color: var(--main-color-one);
    }

    .price {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 10px;
      margin-right: 0;
      color: var(--main-color-three);
    }

    .sub-title {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: var(--heading-color);

      span {
        color: var(--paragraph-color);
      }
    }

    .awarded {
      background: rgba(33, 170, 104, 0.15);
      border: 1px solid #21AA68;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 0;
      padding: 4px 10px;
      font-size: 12px;
      margin-top: -5px;
      color: var(--main-color-three);
    }
  }

  @media only screen and (max-width: 991px) {
    .ref {
      font-size: 20px;
    }
    .title {
      font-size: 15px;
    }
  }
}

.updateRequest {
  .contact-form-wrap {
    padding: 0;
    box-shadow: none;
  }

  .modal-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 2;
    text-align: center;
    color: var(--heading-color);
    font-family: var(--body-font) !important;
    margin: 0;
  }

  .subCategoryHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .awarded {
    background: rgba(33, 170, 104, 0.08);
    border: 1px solid #21AA68;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 4px 10px;
    font-size: 12px;
    margin-top: -5px;
    color: var(--main-color-three);
    cursor: pointer;
  }
}

.tender-subcategory {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 100% !important;
  margin-bottom: 0px;
  display: block;
  color: var(--main-color-one);
}

.tender-title {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 100% !important;
  margin-bottom: 0px;
  padding-right: 50px !important;
}

.photo-viewer-container {
  height: auto !important;
  width: 100% !important;
}

.photo-viewer-container > img {
  height: auto !important;
  width: 100% !important;
}

.btn-yellow > i {
  color: #fff !important;
}
