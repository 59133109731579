.banner-search-wrap {
  .rld-main-search {
    padding: 25px 30px 19px 30px;
    margin-bottom: 25px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: var(--heading-color);
  }

  .btn-yellow {
    height: 48px !important;
  }

  .btn-seeAll {
    height: 35px !important;
  }
  

  .multiSelectContainer .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    min-height: 48px !important;
    position: relative;
    background: #fff;
  }

  .multiSelectContainer .optionListContainer .categoryHolder {
    padding: 2px 10px;
  }

  .form-container {
    background-color: #fff;
    padding: 20px 20px 2px 20px;
    border-radius: 10px;
  }

  //.css-yk16xz-control {
  //  border: 1px solid #1c7430;
  //  box-shadow: 0 0 0 1px #1c7430;
  //}

  .css-yk16xz-control:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .css-1pahdxg-control {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .css-1pahdxg-control:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
  }

  .rld-single-input {
    border: 1px solid var(--component-color)!important;
    //box-shadow: 0 0 0 1px var(--main-color-one);
    //border-radius: 4px;
  }

  .rld-single-input:hover {
    border: 1px solid var(--main-color-one);
    box-shadow: 0 0 0 1px var(--main-color-one);
    border-radius: 4px;
  }

  .rld-single-input {
    border: 0px solid #D4D4D4;
    border-radius: 4px;
  }
}

.banner-inner-wrap {
  .banner-title-1 {
    color: #fff;
    margin: 0;
    font-weight: 300;
  }

  .banner-title-2 {
    color: #fff;
    font-weight: bold;
  }

  .banner-para {
    color: #fff;
    font-size: 15px;
  }
}

.btn-findTender {
  background-color: var(--main-color-one);
  color: #fff;
  height: 50px !important;
  width: 200px;
}

@media only screen and (max-width: 991px) {
  .mobile-content {
    display: flex;
    justify-content: center;
  }

  .banner-title-1 {
    text-align: center;
  }

  .banner-title-2 {
    text-align: center;
    color: #fff;
  }

  .banner-para {
    text-align: center;
  }

  .banner-inner-wrap {
    padding: 50px 0 0px 0;
  }
}

.et-awards {
  z-index: 9990 !important;
}

.banner-square {
  position: absolute;
  top: -20%;
  z-index: 999 !important;
  margin: auto;
  bottom: -50%;
}


@media only screen and (max-width: 1101px) {
  .banner-square {
    display: none;
  }

}
