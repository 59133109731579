.statistics{
  //min-height: 240px;
  display: flex;
  align-items: center;
  //padding-top: 30px;
  //padding-bottom: 30px;
  background-color: #fff;

  .container{
    //background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
  }

  .staticTile{
    padding-top: 25px;
    padding-bottom: 25px;

    .count{
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin: 0;
      color: var(--main-color-one);
    }
    .title{
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: var(--heading-color);
      margin-bottom: 0;
    }
  }
}
