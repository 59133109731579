.myProfile{
  .companyImage{
    width: 100px;
    height: 100px;
    border: 1px solid #CFD3DE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
  }
  .companyCoverImage{
    width: 300px;
    height: 100px;
    border: 1px solid #CFD3DE;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    object-fit: contain;
  }
  .choose {
    color: var(--main-color-one);
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    margin: 0 20px;
    padding: 5px 15px;
    cursor: pointer;
    border: none;
    filter: none;
  }
  .remove {
    color: var(--main-color-two);
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 1;
    margin: 0 20px;
    padding: 10px 0px;
    cursor: pointer;
    border: none;
    filter: none;
  }
  .rld-single-input{
    margin-bottom: 1rem;
  }
}

.placeholderImage{
  .regImg{
    width: 50%;
    height: 60%;
    margin: 20px 0;
  }
}

.mySubscription{
  background: #FFFFFF;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;

  .subDetailTopic{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: var(--heading-color);
  }

  .subscriptionHolder{
    background: #FFFFFF;
    box-shadow: 0px 5px 22px rgba(0, 100, 227 , 0.15);
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .title{
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      text-transform: uppercase;
      color: #A3B1B1;
      padding: 2rem;
    }

    .priceTag {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: var(--heading-color);
      display: flex;
      justify-content: center;
    }

    .price {
      font-style: normal;
      font-weight: 400;
      font-size: 29px;
      line-height: 48px;
      color: var(--heading-color);
      margin: 0;
    }

    .userTag {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 1;
      text-align: center;
      color: var(--paragraph-color);
    }
    .btn{
      width: fit-content;
      margin-top: 1rem;
    }
  }

  .topic{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--heading-color);
    display: flex;
  }

  .validity{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--paragraph-color);
  }
}

@media only screen and (max-width: 991px) {
  .myProfile {
    .companyCoverImage {
      width: 100px;
      height: 100px;
      border: 1px solid #CFD3DE;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 5px;
      object-fit: contain;
    }
  }
}

.packageChange{
  .change-package-back{
    text-align: right;
  }
}

.subscriptionHolder{
  .renewPayment{
    cursor: pointer;
  }
}
