.hr-line {
  background-color: #ffffff;
  height: 0.5px;
  width: 100%;
  margin-top: 25px;
}

.pg-content {
  color: #fff;
  line-height: 28px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

.contact-details-content {
  color: #fff;
  line-height: 30px;
  font-family: 'Roboto', sans-serif;
}

.rights-content {
  font-size: 12px;
  padding-top: 20px;
  display: block;
  font-family: 'Roboto', sans-serif;
}

.footer-pages-content {
  color: #fff;
}

.footer-pages-content > li {
  list-style: none;
  line-height: 40px;
}

.footer-pages-content > li > a {
  color: #fff;
  font-weight: normal !important;
  font-family: 'Roboto', sans-serif;
}

.footer-pages-content > li > a:hover {
  color: #01d92a !important;
}

.footer-white {
  padding-top: 20px;
  padding-bottom: 10px;

  .white-footer-right {
    text-align: right;
  }

  .color-main {
    color: var(--main-color-one);
  }
}

@media only screen and (max-width: 768px) {
  .white-footer-left {
    text-align: center;
  }

  .white-footer-right {
    text-align: center !important;
  }

  .rights-content {
    display: none;
  }

  .pg-content {
    text-align: center;
  }

  .contact-details-content {
    text-align: center;
    margin-top: 20px;
  }

  .footer-pages-content {
    text-align: center;
    margin-top: 20px;
  }

  .footer-social {
    text-align: center;
  }

  .logo-white-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .logo-white-container > a > img {
    width: 100%;
  }
}

.footer-area {
  z-index: 1 !important;
}
